import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpErrorResponse,
  HttpClient,
} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, pipe, Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Store, select } from "@ngrx/store";
import * as fromUserReducer from "src/app/store/reducers/user/index";
// import { Socket } from 'ngx-socket-io';
import { AudioService } from "../shared/audio/audio.service";

import * as sbarAlertActions from "src/app/store/actions/sbar-alerts/sbar-alerts.actions";
import { socket } from "../config/socket";
import { UserRolesMap } from "../shared/accessControl/roleInterface";

@Injectable({
  providedIn: "root",
})
export class SbarService extends UserRolesMap {
  public user;
  public user$ = this.store.pipe(select(fromUserReducer.getCurrUser));

  constructor(
    private http: HttpClient,
    private store: Store<{}>,
    private _audioService: AudioService // private identification
  ) {
    super();
    this.user$.subscribe((data) => {
      if (data && data.email) {
        this.user = data;
      }
    });
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  /**
   * @description This is used to add a sbar
   * @param {sbar} data - Sbar object
   * @author Suraj Shenoy B
   * @date Nov 26 2021
   * @returns Observable
   */
  createSbar(data): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + "sbar", data, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  /**
   * @description This is used to edit a sbar
   * @param {sbar} data - Sbar object
   * @author Suraj Shenoy B
   * @date Nov 26 2021
   * @returns Observable
   */
  updateSbar(data): Observable<any> {
    return this.http
      .patch<any>(
        `${environment.apiUrl}sbar/${data.CPMRN}/${data.encounters}`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  /**
   * @description This is used to take an action on the sbar
   * @param {sbar} data - Sbar object
   * @author Suraj Shenoy B
   * @date Nov 26 2021
   * @returns Observable
   */
  takeActionSbar(data): Observable<any> {
    return this.http
      .patch<any>(
        `${environment.apiUrl}sbar/action/${data.CPMRN}/${data.encounters}`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  getSbarDetails(data: object): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}patients/sbar/get`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  private identification: any = null;

  private patientInfoUpdateSource = new BehaviorSubject(this.identification);
  notifyPatientInfoUpdate = this.patientInfoUpdateSource.asObservable();

  getPatientInfo(value) {
    this.patientInfoUpdateSource.next(value);
  }

  startSocket(channel, role) {
    socket.on(channel, (sbarData) => {
      let sbar = {
        _id: sbarData.data.sbarId,
        urgency: sbarData.data.urgency,
        issue: sbarData.data.issue,
        patientName: sbarData.data.name,
        hospitalName: sbarData.data.hospitalName,
        unitName: sbarData.data.unitName,
        CPMRN: sbarData.data.CPMRN,
        encounters: sbarData.data.encounters,
        type: sbarData.data.type,
      };

      if (sbar.urgency) {
        // show new admission alert to nurse
        if (
          sbar.urgency === "New Admission" &&
          (role == this.NURSE || role == this.CCN || role == this.CCA)
        ) {
          this.addSbar(sbar);
          // this._audioService.playSbarAlert();
        }

        // show all sbar alerts
        if (
          role === this.PHYSICIAN ||
          role == this.SPECIALIST ||
          role == this.CCAPHYSICIAN
        ) {
          this.addSbar(sbar);

          // play music
          if (sbar.urgency == "High") {
            // this._audioService.playSbarAlertHigh();
          } else {
            // this._audioService.playSbarAlert();
          }

          // hide sbar
          if (sbar.urgency == "Medium" || sbar.urgency == "Low") {
            setTimeout(() => {
              this.removeSbar(sbar);
            }, 5000);
          }
        }
      }
    });

    socket.on("onCloseSbarAlert", (sbar) => {
      this.removeSbar(sbar);
    });

    socket.on("sbar-data-updated", (data) => {
      let sbar = { ...data, _id: new Date(data.createDateTime).getTime() };

      // this.removeSbar(sbar);
    });
  }

  stopSocket(channel) {
    socket.removeAllListeners(channel);
    socket.removeAllListeners("onCloseSbarAlert");
    socket.removeAllListeners("sbar-data-updated");
  }

  addSbar(sbar) {
    this.store.dispatch(sbarAlertActions.addSbarAlert({ sbar }));
  }

  removeSbar(sbar) {
    this.store.dispatch(sbarAlertActions.removeSbarAlert({ sbar }));
  }

  // emitter to know if patient is dicharged
  public patientDischargeListener = new Subject();
  public patientDischarged = (CPMRN) => {
    this.patientDischargeListener.next(CPMRN);
  };
}
