import { Observable, Subject } from "rxjs";
import { select, Store } from "@ngrx/store";
import { take, takeUntil } from "rxjs/operators";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

import { Order } from "../../../models/Order.model";
import * as fromUser from "../../../store/reducers/user";
import * as fromOrder from "../../../store/reducers/order";
import * as fromProtocol from "../../../protocol/reducers";
import { FileService } from "../../../services/file.service";
import { ActionTypes, OrderViewActions } from "../../../store/actions/order";
import { PatientService } from "src/app/services/patient.service";
import * as fromIoReducer from "../../../store/reducers/patient-chart/io";
import { HistoryModalData } from "../../../models/order/OrderHistory.model";
import {
  nurse,
  physician,
  np,
  physicianAssociate,
} from "src/app/patient/orders/orders-view/permissions";
import { ProtocolFileData } from "../../../models/protocol/ProtocolFileDate.model";
import { DetailsModalComponent } from "../../../orders/components/details-modal/details-modal.component";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { SelectedButtonTag } from "src/app/iris-components/btn-tag/btn-tag.component";
import { routes } from "../../../orders/form.data";
import { F } from "@angular/cdk/keycodes";
import {
  NON_DRUG_CLASS_TO_TEXT_MAP,
  NON_DRUG_TEXT_TO_CLASS_MAP,
} from "./order-view.data";
import { NgxPermissionsService } from "ngx-permissions";
import { OrderPanelComponent } from "./order-panel/order-panel.component";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";

export const linkedOrderDiscontinueReason =
  "This order was edited to a new currently active order.";
export const linkedOrderTooltip =
  "This is a currently active order that is being edited. Once the edit is signed it will replace the currently active order.";

@Component({
  selector: "app-orders-view",
  templateUrl: "./orders-view.component.html",
  styleUrls: ["./orders-view.component.scss"],
})
export class OrdersViewComponent
  extends UserRolesMap
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild("searchDropdown") searchDropdownEle: ElementRef;
  @ViewChild(OrderPanelComponent) orderPanelComponent: OrderPanelComponent;
  public currentPatient;
  public orders;
  public ordersView = [];
  public state;
  private user;
  public routes = routes;
  public currentTab = "activeTotal";
  public activeProtocolKey = "activeTotal";
  public activeProtocols;
  private unsubscribe$: Subject<any> = new Subject<any>();
  public getIo$ = this.store.pipe(select(fromIoReducer.getIoData));
  public protocolTitle = "Active Protocols";
  public protocolsForm: UntypedFormGroup;
  public protocols;
  public selectedProtocols: string[] = [];
  public proceduresConnected = {};
  public isDischargeTimeElapsed = false;
  public protocolTitleMap = new Map([
    ["active", "Active Protocols"],
    ["pending", "Pending Protocols"],
    ["completed", "Completed Protocols"],
    ["inactive", "Discontinued Protocols"],
    ["pta", "Home Med Protocols"],
  ]);

  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
      this.loadOrders("completed", false);
      this.loadOrders("pending", false);
      this.loadOrders("pta", false);
      this.loadOrders("inactive", false);
      this.loadOrders("active", false);
    }
  }

  public selectedOrders: Order[] = [];
  selectedTabIndex: number = 0;
  showFilters: boolean = true;

  filterControls: {
    orderName: string;
    protocol: string;
    route: string;
    status: string;
  };

  iconLeft: string = "assets/icons/attach_file.svg";
  iconRight: string = this.ngxPermissionsService.getPermission(
    "discontinue_protocol"
  )
    ? "assets/icons/cancel_rounded.svg"
    : null;
  leftIconTooltip: string = "View Attached File";
  rightIconTooltip: string = "Discontinue Protocol";
  public discontinueProtocol: any;

  // search variables
  showSearch: boolean = false;
  searchText: string = "";
  private INDEX_TO_ORDER_CATEGORY_MAP = {
    0: ["active", "activeD"],
    1: ["pending"],
    2: ["completed"],
    3: ["inactive"],
    4: ["pta"],
  };
  private SEARCH_PARAMS_DRUG_ORDERS = ["name", "brandName", "atcClass"];
  private SEARCH_PARAMS_NON_DRUG_ORDERS = [
    "name",
    "title",
    "brandName",
    "type",
    "investigation",
    "pType",
    "airway",
  ];
  private DRUG_ORDER = "medications";
  public searchTextToBeHighlighted: string;
  public searchDropdown: boolean = false;
  public searchResultCount: number = 0;

  // end search variables

  public activeProtocols$ = this.store.pipe(
    select(fromOrder.getActiveProtocolState),
    takeUntil(this.unsubscribe$)
  );

  public orders$ = this.store.pipe(
    select(fromOrder.getOrders),
    takeUntil(this.unsubscribe$)
  );

  @HostListener("document: click", ["$event"])
  clickInside(event?) {
    if (!event?.target) return;
    if (!this.searchDropdownEle?.nativeElement.contains(event.target)) {
      this.searchDropdown = false;
    }
  }

  constructor(
    private store: Store<any>,
    private userStore: Store<fromUser.State>,
    private orderStore: Store<fromOrder.State>,
    private protocolStore: Store<fromProtocol.State>,
    private formBuilder: UntypedFormBuilder,
    private _patientService: PatientService,
    private ngxPermissionsService: NgxPermissionsService,
    private fileService: FileService,
    private dialog: MatDialog
  ) {
    super();
    this.userStore.pipe(select(fromUser.getCurrUser)).subscribe((data) => {
      this.user = data;
    });
  }

  ngOnInit() {
    this.orders$.subscribe((result) => {
      this.orders = result;
      this.protocolsForm = this.initActiveProtocols(
        this.orders[this.activeProtocolKey]["activeProtocols"]
      );
    });

    this.activeProtocols$.subscribe((data) => {
      this.activeProtocols = data;

      this.protocolsForm = this.initActiveProtocols(
        this.orders[this.activeProtocolKey]["activeProtocols"]
      );
    });

    this.orderStore
      .pipe(
        select(fromOrder.getActiveProtocolsByCategory, this.activeProtocolKey),
        take(1)
      )
      .subscribe((data) => {
        this.protocolsForm = this.initActiveProtocols(data);
      });

    this.protocols = this.protocolsForm.get("protocols") as UntypedFormArray;

    /** Initializing filter controls */
    this.filterControls = {
      orderName: "",
      protocol: "",
      route: "",
      status: "",
    };

    this.getIo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currPatient.firstChange) {
      this.setState(this.user, "active");
    }
  }

  // get IO data
  getIo() {
    this.getIo$.subscribe(
      (io) => {
        if (io["proceduresConnected"]) {
          this.doIoMath(io);
        }
      },
      (err) => {
        // console.log('Server error');
      }
    );
  }

  // use the io data
  doIoMath(data) {
    this.proceduresConnected = data["proceduresConnected"];
  }

  initActiveProtocols(protocols: string[]): UntypedFormGroup {
    return new UntypedFormGroup({
      protocols: protocols.reduce((acc, val) => {
        // this.hasFile(val).subscribe((isfilePresent) => {
        // 	filePresent = isfilePresent;
        // });

        const filePresent = this.activeProtocols?.find((activeProtocol) => {
          return activeProtocol.name === val;
        })?.file?.key;

        acc.push(
          new UntypedFormGroup({
            name: new UntypedFormControl(val),
            checked: new UntypedFormControl(false),
            attachmentIcon: filePresent
              ? new UntypedFormControl(this.iconLeft)
              : new UntypedFormControl(),
          })
        );

        return acc;
      }, new UntypedFormArray([])),
    });
  }

  addProtocol(selectedBtnTag: SelectedButtonTag, index: number): void {
    if (selectedBtnTag.selected) {
      this.selectedProtocols.push(selectedBtnTag.tagName);
    } else {
      this.selectedProtocols = this.selectedProtocols.filter(
        (val) => val !== selectedBtnTag.tagName
      );
    }

    this.protocolsForm.value.protocols[index].checked = selectedBtnTag.selected;

    // removes duplicate elements from selectedProtocols array.
    this.selectedProtocols = [...new Set(this.selectedProtocols)];
    this.selectedOrders = this.getSelectedOrders(
      this.orders,
      this.selectedProtocols,
      this.currentTab
    );
  }

  setState(user, type) {
    const role = user.role.toLowerCase();

    if (["physician", "specialist", "cca physician"].includes(role)) {
      this.state = physician[type];
    } else if (
      role == this.CCA.toLocaleLowerCase() ||
      role == this.CCN.toLocaleLowerCase()
    ) {
      this.state = np[type];
    } else if (role === "physician associate") {
      this.state = physicianAssociate[type];
    } else {
      this.state = nurse[type];
    }
    // add order category to state object
    this.state["type"] = type;
    this.state["name"] = user.name;
    this.state["title"] = user.title;
    this.state["role"] = user.role;
    this.isDischargeTimeElapsed =
      this._patientService.checkIfDischargeTimeElapsed(this.currentPatient);
    this.state.isDischargeTimeElapsed = this.isDischargeTimeElapsed;
  }

  //type : tab id
  loadOrders(type, setState = true) {
    if (setState) {
      this.setState(this.user, type);
    }

    // this.store.dispatch({
    //   type: actions.ActionTypes.listOrders,
    //   payload: {
    //     type: type,
    //     CPMRN: this.currentPatient.CPMRN,
    //     encounters: this.currentPatient.encounters
    //   }
    // });
  }

  getActiveDiscard() {
    let state = {};
    let type = "activeD";
    if (
      ["physician", "specialist", "cca physician"].includes(
        this.user?.role?.toLowerCase()
      )
    ) {
      state = physician[type];
    } else if (this.user?.role?.toLowerCase() === "physician associate") {
      state = physicianAssociate[type];
    } else {
      state = nurse[type];
    }
    // add order category to state object
    state["type"] = type;
    state["name"] = this.user.name;
    state["title"] = this.user.title;
    state["role"] = this.user.role;

    this.isDischargeTimeElapsed =
      this._patientService.checkIfDischargeTimeElapsed(this.currentPatient);
    state["isDischargeTimeElapsed"] = this.isDischargeTimeElapsed;
    return state;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  open(content) {
    this.dialog.open(content, {
      width: "500px",
      autoFocus: false,
      disableClose: true,
    });
  }

  setProtocolTitle(category: string): string {
    return this.protocolTitleMap.get(category);
  }

  /*
   * NAME: discontinue
   * PURPOSE: filters out the orders for discontinue
   * DESCRIPTION:
   * PARAMS: void
   * RETURNS: void
   * USED BY: order-view-component.html
   * CREATED DATE: 10/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  discontinue(): void {
    let activeTab = this.activeProtocolKey;
    this.selectedOrders = [];

    if (
      ["physician", "specialist", "cca physician"].includes(
        this.user?.role?.toLowerCase()
      ) &&
      activeTab === "active"
    ) {
      activeTab = "activeTotal";
    }

    if (this.user) {
      let tempOrders = this.getSelectedOrders(
        this.orders,
        this.selectedProtocols,
        activeTab
      );

      if (
        this.user.role.toLowerCase() == this.NURSE.toLocaleLowerCase() ||
        this.user.role.toLowerCase() == this.CCA.toLocaleLowerCase() ||
        this.user.role.toLowerCase() == this.CCN.toLocaleLowerCase() ||
        this.user.role.toLowerCase() == this.PA.toLocaleLowerCase()
      ) {
        tempOrders = tempOrders.filter((order) => !order.toBeDiscarded);
      }

      this.selectedOrders = tempOrders;
    }
  }

  /*
   * NAME: sign
   * PURPOSE: calls getSelectedOrders to filter the orders for sign
   * DESCRIPTION:
   * PARAMS: void
   * RETURNS: void
   * USED BY: order-view-component.html
   * CREATED DATE: 10/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  sign(): void {
    this.selectedOrders = this.getSelectedOrders(
      this.orders,
      this.selectedProtocols,
      "pending"
    );
  }

  /*
   * NAME: getSelectedOrders
   * PURPOSE: filters out orders
   * DESCRIPTION: filters out orders based on selectedProtocols and category
   *   - fetches the order of provided category
   *   - only selects the order which are of selectedProtocols
   * PARAMS:
   *   - orders:any                  - orders object present in store
   *   - selectedProtocols:string[]  - selectedProtocols in array
   *   - category:string             - category of orders (pending, active, etc)
   * RETURNS: Order[] - array of orders
   * USED BY: order-view-component.html
   * CREATED DATE: 10/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  getSelectedOrders(
    orders: any,
    selectedProtocols: string[],
    category: string
  ): Order[] {
    let result = [];

    if (orders) {
      const ordersObject = orders[category];
      const keys = Object.keys(ordersObject).filter(
        (elem) => elem !== "count" && elem !== "protocolsForm"
      );

      for (const key of keys) {
        const orderArray = ordersObject[key];
        for (const order of orderArray) {
          if (selectedProtocols.indexOf(order.protocol) === -1) {
            continue;
          }

          result.push(order);
        }
      }
    }

    return result;
  }

  /*
   * NAME: onDiscontinue
   * PURPOSE: fires discontinueProtocol action with orders.
   * DESCRIPTION: fires discontinueProtocol action with orders.
   *   also resets selectedProtocols and selectedOrders to empty.
   * PARAMS: void
   * RETURNS: void
   * USED BY: order-view-component.html
   * CREATED DATE: 10/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  onDiscontinue(protocol): void {
    const ordersList = this.getSelectedOrders(
      this.orders,
      [protocol],
      this.currentTab
    );
    this.store.dispatch(
      OrderViewActions.discontinueProtocol({ orders: ordersList })
    );
  }

  /*
   * NAME: onSign
   * PURPOSE: fires signProtocol action with orders.
   * DESCRIPTION: fires signProtocol action with orders.
   *   also resets selectedProtocols and selectedOrders to empty.
   * PARAMS: void
   * RETURNS: void
   * USED BY: order-view-component.html
   * CREATED DATE: 10/10/2019
   * AUTHOR: Gunjit Agrawal
   */
  onSign(): void {
    const userName =
      this.user && this.user.title && this.user.title !== ""
        ? this.user.title + " " + this.user.name
        : this.user.name;

    this.store.dispatch(
      OrderViewActions.signProtocol({
        orders: this.selectedOrders,
        user: userName,
      })
    );
    this.selectedProtocols = [];
    this.selectedOrders = [];
  }

  /*
   * NAME: getProtocolFile
   * PURPOSE: fetches file data from store by protocol name
   * DESCRIPTION:
   * PARAMS: name:string - protocol name
   * RETURNS: Observable<ProtocolFileData>
   * USED BY: orders-view.component.html
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  getProtocolFile(name: string): Observable<ProtocolFileData> {
    return this.protocolStore.pipe(
      select(fromProtocol.getProtocolFileByName, name)
    );
  }

  /*
   * NAME: hasFile
   * PURPOSE: checks whether a protocol has a file
   * DESCRIPTION:
   * PARAMS: name:string - name of the protocol
   * RETURNS: Observable:any - observable with file data in it (name and key)
   * USED BY: orders-view.component.html
   * CREATED DATE: 20/01/20
   * AUTHOR: Gunjit Agrawal
   */
  hasFile(name: string): Observable<any> {
    return this.orderStore.pipe(select(fromOrder.doesProtocolHaveFile, name));
  }

  /*
   * NAME: downloadProtocolFile
   * PURPOSE: downloads the file for a protocol
   * DESCRIPTION:
   * PARAMS: name:string - name of the protocol
   * RETURNS: void
   * USED BY: orders-view.component.html
   * CREATED DATE: 20/01/20
   * AUTHOR: Gunjit Agrawal
   */
  downloadProtocolFile(name: string): void {
    this.store.dispatch(OrderViewActions.getProtocolFile({ protocol: name }));
  }

  /*
   * NAME: getFileData
   * PURPOSE: calls fileService getFileData to get file data
   * DESCRIPTION:
   * PARAMS: data:string - file data encoded in base64
   * RETURNS: SafeResourceUrl - bypassed security check url
   * USED BY: protocol-form.component.html
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  getFileData(data) {
    return this.fileService.getFileData(data);
  }

  /*
   * NAME: openFileModal
   * PURPOSE: opens up modal
   * DESCRIPTION:
   * PARAMS: modal:any - template reference variable mentioned in html
   * RETURNS: void
   * USED BY: protocol-form.component.html
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  openFileModal(modal: any, file): void {
    window.open(file.data, "", "left=500, top=100, width=1000, height=800");

    // this.dialog.open(modal, {
    //   width: "1050px",
    //   disableClose: true,
    // });
  }

  /**
   * Fires discontinue action
   *
   * @param {Object} data - order data for discontinue api
   */
  onDiscontinueOrder(data): void {
    this.store.dispatch({ type: ActionTypes.discontinue, payload: data });
  }

  /**
   * Opens details modal with order details and history in it.
   *
   * @param {Object} data - contains order data with orderName in it.
   */
  showDetailsModal(data, activeTab): void {
    const historyModalData: HistoryModalData = {
      orderId: data._id,
      orderName: data.orderName,
      category: data.category,
      type: data.type,
      brandName: data.brandName,
    };

    const modalRef = this.dialog.open(DetailsModalComponent, {
      data: historyModalData,
      width: "650px",
      autoFocus: false,
      disableClose: true,
    });
  }

  /**Method to clear all the filters applied */
  clearFilters() {
    this.filterControls = {
      orderName: "",
      protocol: "",
      route: "",
      status: "",
    };
  }

  hideFilters(hide: boolean) {
    this.showFilters = !hide;
  }

  tabChanged(event: MatTabChangeEvent) {
    const tabNames = [...this.protocolTitleMap.keys()];
    this.protocolTitle = this.setProtocolTitle(tabNames[event.index]);
    this.activeProtocolKey =
      tabNames[event.index] === "active"
        ? "activeTotal"
        : tabNames[event.index];
    this.selectedProtocols = [];
    this.selectedOrders = [];

    this.iconRight =
      this.ngxPermissionsService.getPermission("discontinue_protocol") &&
      tabNames[event.index] !== "inactive" &&
      tabNames[event.index] !== "completed"
        ? "assets/icons/cancel_rounded.svg"
        : null;

    this.currentTab =
      tabNames[event.index] === "active"
        ? "activeTotal"
        : tabNames[event.index];
    this.loadOrders(tabNames[event.index]);
    this.orderStore
      .pipe(
        select(fromOrder.getActiveProtocolsByCategory, this.activeProtocolKey),
        take(1)
      )
      .subscribe((protocols) => {
        this.protocolsForm = this.initActiveProtocols(protocols);
      });
  }

  onIconClick(
    viewAttachedFile: boolean,
    protocol,
    discontinue_modal,
    fileModal
  ) {
    if (viewAttachedFile) {
      this.downloadProtocolFile(protocol.name);
      this.getProtocolFile(protocol.name).subscribe((file) => {
        this.openFileModal(fileModal, file);
      });
    } else {
      this.discontinueProtocol = protocol.name;
      this.dialog.open(discontinue_modal, {
        width: "500px",
        autoFocus: false,
        disableClose: true,
      });
    }
  }

  onSearch() {
    if (!this.searchText || this.searchText?.trim().length < 3) return;

    const searchTerm = this.searchText.trim().toLowerCase();
    this.searchResultCount = this.findTextInOrder(searchTerm);
    this.searchDropdown = true;
    // if we are searching the same order then scrolling should work
    if (searchTerm === this.searchTextToBeHighlighted)
      this.orderPanelComponent.findSearchText();
    this.searchTextToBeHighlighted = searchTerm;
  }

  /**
   *
   * @param searchText - text to be searched
   * @description - searches text from order based on active order [active/pending/completed etc] and orderType [drug/non-drug]
   * @returns - number of found result
   * @author Rajat Saini
   */
  findTextInOrder(searchText) {
    const [...orderToSearch] =
      this.INDEX_TO_ORDER_CATEGORY_MAP[this.selectedTabIndex];
    let searchResult = 0;
    if (!orderToSearch || !orderToSearch?.length) return searchResult;

    return orderToSearch.reduce((result, orderName) => {
      const orders: any = this.orders[orderName];

      if (!orders) return result;

      let orderKey: string;
      let orderValue: any;
      let isClassVisited = [];
      for ([orderKey, orderValue] of Object.entries(orders)) {
        if (orderKey === "count" || !orderValue?.length) continue;

        const searchParamArrayKey =
          orderKey === this.DRUG_ORDER
            ? "SEARCH_PARAMS_DRUG_ORDERS"
            : "SEARCH_PARAMS_NON_DRUG_ORDERS";
        const classKey = orderKey == this.DRUG_ORDER ? "atcClass" : "type";

        result += orderValue?.reduce((num, order) => {
          let classExactValue = null;
          let classDisplayName = null;
          const keysToBeSearched = isClassVisited.includes(order[classKey])
            ? this[searchParamArrayKey].filter((key) => key != classKey)
            : this[searchParamArrayKey];
          const isFoundInOrderNameBrandClass = keysToBeSearched.filter(
            (searchPRMKey) => {
              if (!order[searchPRMKey]) return false;
              if (orderKey != this.DRUG_ORDER && searchPRMKey == classKey) {
                const classKey = Object.keys(NON_DRUG_TEXT_TO_CLASS_MAP).find(
                  (clName) => clName.includes(searchText)
                );
                classExactValue = classKey
                  ? NON_DRUG_TEXT_TO_CLASS_MAP[classKey]?.toLowerCase()
                  : null;

                if (!classExactValue) {
                  const classExactValueTemp = Object.keys(
                    NON_DRUG_CLASS_TO_TEXT_MAP
                  )
                    .find((clName) => clName.includes(searchText))
                    ?.toLowerCase();
                  classDisplayName = classExactValueTemp
                    ? NON_DRUG_CLASS_TO_TEXT_MAP[
                        classExactValueTemp
                      ]?.toLowerCase()
                    : null;
                }
              }
              return classExactValue || classDisplayName
                ? order[searchPRMKey]
                    .trim()
                    .toLowerCase()
                    .includes(classExactValue || classDisplayName)
                : order[searchPRMKey].trim().toLowerCase().includes(searchText);
            }
          )?.length;
          const isFoundInCombination = order?.combination?.length
            ? order?.combination.filter((combi) =>
                combi.name.trim().toLowerCase().includes(searchText)
              )?.length
            : 0;

          if (!classExactValue) {
            classExactValue = Object.keys(NON_DRUG_CLASS_TO_TEXT_MAP)
              .find((clName) => clName.includes(searchText))
              ?.toLowerCase();
          }
          if (
            order[classKey] &&
            order[classKey]
              .trim()
              .toLowerCase()
              .includes(classExactValue || searchText)
          )
            isClassVisited.push(order[classKey]);

          return num + isFoundInCombination + isFoundInOrderNameBrandClass;
        }, 0);
      }
      return result;
    }, 0);
  }

  resetSearch() {
    this.searchTextToBeHighlighted = "";
    this.searchText = "";
    this.searchDropdown = false;
    this.searchResultCount = 0;
  }
}
