import { Component, OnInit, OnDestroy } from "@angular/core";
import { SbarService } from "src/app/services/sbar.service";
import { Store, select } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import * as fromUserReducer from "src/app/store/reducers/user";
import * as fromSbarAlertReducer from "src/app/store/reducers/sbar-alerts";
import { socket } from "../../../config/socket";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";
// import { Socket } from 'ngx-socket-io';

@Component({
  selector: "app-sbar-alert",
  templateUrl: "./sbar-alert.component.html",
  styleUrls: ["./sbar-alert.component.scss"],
})
export class SbarAlertComponent
  extends UserRolesMap
  implements OnInit, OnDestroy
{
  public channel: string;
  private unsubscribe$: Subject<any> = new Subject<any>();
  private sBarRoles: string[] = [
    this.PHYSICIAN,
    this.CCA,
    this.CCN,
    this.NURSE,
    this.SPECIALIST,
    this.CCAPHYSICIAN,
  ];
  public $sbarAlert = this.store.pipe(
    select(fromSbarAlertReducer.getSbarAlerts)
  );

  constructor(private _sbarService: SbarService, private store: Store<{}>) {
    super();
  }

  ngOnInit() {
    this.store
      .pipe(select(fromUserReducer.getCurrUser), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        let role = data && data.role;

        if (this.sBarRoles.includes(role)) {
          this.channel = data && data.email && data.email.replace("@", ".");
          this._sbarService.startSocket(this.channel, role);
        }
      });
  }

  onClose(sbarAlert) {
    this._sbarService.removeSbar(sbarAlert);

    socket.emit("closeSbarAlert", sbarAlert);
  }

  alertCLicked(sbarAlert) {
    this.onClose(sbarAlert);

    setTimeout(() => {
      window.open(
        "/patient/" + sbarAlert.CPMRN + "/" + sbarAlert.encounters,
        "_blank"
      );
    }, 0);
  }

  onClosePatientAssignAlert(alert) {
    this._sbarService.removeSbar(alert);
  }

  ngOnDestroy() {
    if (this.channel) this._sbarService.stopSocket(this.channel);
  }
}
